.navLinkFooter{
    font-weight: 400;
    font-size: 15px;
}
.footerHover {
    transition: 0.2s;

}

.footerHover:hover {
    color: #FE9901;
}
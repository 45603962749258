
.navLink{
    border: 2px solid black;
    border-radius: 50px;
    padding: 7px;
    
}
.navLinkBtns{
    font-weight: 600;
    letter-spacing: 1.5px;
    font-size: 15px;
    cursor: pointer;
    transition: 0.2s;
}
.navLinkBtns:hover {
    color: #FE9901;
}
.forCall {
    font-weight: 600;
    font-size: 16px;
}
.smallLink{
    font-weight: 600;
    font-size: 14px;
}

.hashLink{
    cursor: pointer;
}
@media only screen and (max-width:1200px) {
    .navLink{
        border: 0;
        border-radius: 0;
        padding: 0;
    } 
}